import React, { useState } from 'react'
import { Table, Drawer } from 'antd'
import { useAsync } from '@umijs/hooks'
import { getRecommendImagePackage } from '../../utils/api'
import styled from 'styled-components'
import BindDeviceImage from '../../assets/images/how-to-bind-device.png'
import Bind from './bind'
import HelpDoc from './docs/HelpDoc'
import { isMobile } from '@/utils/device'

const Box = styled.div`
  > .title {
    font-size: 22px;
    line-height: 22px;
    margin-top: 40px;
    margin-bottom: 18px;
  }
  .card {
    padding: 24px 32px 16px;
    background-color: #fff;
    margin-bottom: 24px;
    > .title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-bottom: 16px;
    }
    .ant-form-item-control-input,
    .ant-input-number {
      width: 468px;
    }
  }
`

const BindDevice: React.FC<{}> = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [networkDrawerVisible, setNetworkDrawerVisible] = useState(false)
  const { loading: isGettingRecImage, data: recommendImagePackage } = useAsync(
    getRecommendImagePackage,
  )

  return (
    <Box>
      {!isMobile() && <>
        <div className="title">如何刷镜像</div>
        <div className="card">
          <Table
            rowKey="md5"
            columns={[
              {
                title: '镜像包版本',
                dataIndex: 'version',
              },
              {
                title: '镜像包下载地址',
                dataIndex: 'downloadURL',
              },
              {
                title: 'MD5校验码',
                dataIndex: 'md5',
              },
            ]}
            dataSource={[
              recommendImagePackage || {
                downloadURL: '',
                md5: '',
                version: '',
              },
            ]}
            loading={isGettingRecImage}
            pagination={false}
            style={{ marginBottom: 10 }}
            bordered
          />
          <a onClick={() => setDrawerVisible(true)} style={{ cursor: 'pointer', marginRight: 10 }}>
            戳我查看刷镜像教程
          </a>
          <a onClick={() => setNetworkDrawerVisible(true)} style={{ cursor: 'pointer' }}>
            戳我查看配置网络教程
          </a>
          <Drawer
            title="刷镜像教程"
            placement="right"
            width={'66.66%'}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            <HelpDoc docType="doc-x86" />
          </Drawer>
          <Drawer
            title="配置网络教程"
            placement="right"
            width={'66.66%'}
            onClose={() => setNetworkDrawerVisible(false)}
            open={networkDrawerVisible}
          >
            <HelpDoc docType="doc-network" />
          </Drawer>
        </div>
      </>}
      <div className="title">绑定设备</div>
      <Bind />
    </Box>
  )
}

export default BindDevice
