export const NETWORK_MODE = [
  { label: '固定公网单IP', value: '固定公网单IP' },
  { label: '固定公网多IP', value: '固定公网多IP' },
  { label: '路由器上网', value: '路由器上网' },
  { label: '服务器上网', value: '服务器上网' },
]
export const IPTIP = '"双栈"代表开通IPv4和IPv6地址；IPv4代表只开通v4地址；IPv6代表只开通v6地址；对支持IPv6的资源，平台会进行检测及配置，后续会优先进行调整'

export const roomPickerRange = [
  { value: 'IDC机房', label: 'IDC机房' },
  { value: '城域网机房', label: '城域网机房' },
  { value: '托管机房', label: '托管机房' },
]

export const UPSPickerRange = [
  { value: true, label: '有UPS' },
  { value: false, label: '无UPS' },
]

export const IPPickerRange = [
  { value: '双栈', label: '双栈' },
  { value: 'IPv4', label: 'IPv4' },
  { value: 'IPv6', label: 'IPv6' },
]