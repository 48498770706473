import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Tabs, Form, Input, InputNumber, Select, Radio, Tooltip, Modal, message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { QuestionCircleOutlined, ScanOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom'
import { NETWORK_MODE, IPTIP, roomPickerRange, UPSPickerRange, IPPickerRange } from './constant';
import { BindDeviceStyle, BindDeviceFormStyle, Tip, MyButton } from './style';
import { isMobile } from '../../utils/device';
import styles from './bind.module.less';
import { useBindDeviceMutation, useBindSwitchMutation } from './bindSlice';
import { WebSocketService } from '@/utils/websocket';
import queryString from 'query-string';

const { TabPane } = Tabs;
const { Option } = Select;

export default function DeviceConfig() {
  const location = useLocation();
  const history = useHistory()
  // 创建两个独立的表单实例
  const [deviceForm] = Form.useForm();
  const [switchForm] = Form.useForm();
  const [totalBandwidth, setTotalBandwidth] = useState<string>('0.00');
  const [bindDevice] = useBindDeviceMutation();
  const [bindSwitch] = useBindSwitchMutation();
  const ws = WebSocketService.getInstance();
  const { deviceId, type } = queryString.parse(location.search);

  // 根据type参数确定默认选中的tab
  const getDefaultActiveKey = () => {
    if (type === 'sn') {
      return '2';  // 交换机绑定
    }
    return '1';    // 整机绑定
  };

  const handleScanResult = useCallback((data: any) => {
    console.log('Received scan result:', data);
    const { deviceId } = data;
    // 根据当前激活的标签页来决定更新哪个表单
    const activeTab = document.querySelector('.ant-tabs-tab-active')?.textContent;
    if (activeTab === '整机绑定') {
      deviceForm.setFieldsValue({ deviceId });
    } else if (activeTab === '交换机绑定') {
      switchForm.setFieldsValue({ deviceId });
    }
    message.success('已接收到扫描结果');
  }, [deviceForm, switchForm]);

  // useEffect(() => {
  //   console.log('Subscribing to device_scan');
  //   ws.subscribe('device_scan', handleScanResult);

  //   return () => {
  //     console.log('Unsubscribing from device_scan');
  //     ws.unsubscribe('device_scan', handleScanResult);
  //   };
  // }, [handleScanResult]);
  useEffect(() => {
    if (type === 'device') {
      deviceForm.setFieldsValue({deviceID:  deviceId });
    } else if (type === 'sn') {
      switchForm.setFieldsValue({sn: deviceId });
    }
  }, [deviceId])

  // 整机绑定
  const handleAllDeviceBind = (values: any) => {
    bindDevice(values).then((res: any) => {
      if (res && res.data && !res.error) {
        deviceForm.resetFields();
        message.success('绑定成功');
      } else {
        message.error('绑定失败');
      }
    })
  };
  // 交换机绑定
  const handleSwitchBind = (values: any) => {
    bindSwitch(values).then((res: any) => {
      if (res && res.data && !res.error) {
        switchForm.resetFields();
        message.success('绑定成功');
      } else {
        message.error('绑定失败');
      }
    })
  };

  // 计算总带宽
  const calculateTotalBandwidth = (form: FormInstance) => {
    const singleLine = form.getFieldValue('upBandwidth') || 0;
    const lineCount = form.getFieldValue('lineNumber') || 0;
    const totalMbps = singleLine * lineCount;
    const totalGbps = (totalMbps / 1024).toFixed(2);
    setTotalBandwidth(totalGbps);
  };

  // 监听表单值变化
  const handleDeviceFormValuesChange = (changedValues: any) => {
    if ('upBandwidth' in changedValues || 'lineNumber' in changedValues) {
      calculateTotalBandwidth(deviceForm);
    }
  };

  const handleScanClick = (type?: string) => {
    if (!isMobile()) {
      Modal.info({
        title: '提示',
        content: <span>请使用手机浏览器访问网址 <span style={{ color: '#1890ff' }}>https://pedge.network/</span> ，登陆账号后可扫码绑定</span>
      });
      return;
    }
    const url = type === 'sn' ? '/scan-device?type=sn' : '/scan-device';
    history.push(url);
  };

  return (
    <BindDeviceStyle>
      <BindDeviceFormStyle>
        <Tabs defaultActiveKey={getDefaultActiveKey()}>
          <TabPane tab="整机绑定" key="1">
            <Form 
              form={deviceForm} 
              layout="horizontal" 
              onFinish={handleAllDeviceBind}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              onValuesChange={handleDeviceFormValuesChange}
            >
              <Form.Item
                name="deviceID"
                label="设备ID"
                rules={[{ required: true, message: '请输入设备ID' }]}
              >
                <Input placeholder="请输入设备ID" addonAfter={<ScanOutlined style={{ fontSize: 22 }} onClick={() => handleScanClick()} />} />
              </Form.Item>
              <Form.Item name="remark" label="备注">
                <Input.TextArea placeholder="请输入备注（不超过50字符）" maxLength={50} />
              </Form.Item>
              <Form.Item label="单条上行" required>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item name="upBandwidth" noStyle rules={[{ required: true, message: '请输入单条上行' }]} >
                    <InputNumber
                      style={{ width: '100%' }}
                      addonAfter="Mbps"
                      placeholder="单位Mbps，进制1024"
                    />
                  </Form.Item>
                  <span style={{ marginLeft: '16px', whiteSpace: 'nowrap' }}>
                    总：{totalBandwidth}Gbps
                  </span>
                </div>
              </Form.Item>
              <Form.Item name="lineNumber" label="线路数量"  rules={[{ required: true, message: '请输入线路数量' }]} >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="请填写线路数量"
                />
              </Form.Item>
              <Form.Item name="country" label="国家" rules={[{ required: true, message: '请输入国家' }]} >
                <Input placeholder="务必正确填写，否则影响收益！" />
              </Form.Item>
              <Form.Item name="province" label="省份"  rules={[{ required: true, message: '请输入省份' }]} >
                <Input placeholder="务必正确填写，否则影响收益！" />
              </Form.Item>
              <Form.Item name="isp" label="运营商"  rules={[{ required: true, message: '请输入运营商' }]} >
                <Input placeholder="请填写运营商" />
              </Form.Item>
              <Form.Item name="DNS" label="DNS" >
                <Input placeholder="填写DNS有助于提升跑量" />
              </Form.Item>
              <Form.Item name="diallingType" label="上网方式" rules={[{ required: true, message: '请选择上网方式' }]} >
                <Select placeholder="请选择上网方式" >
                  {NETWORK_MODE.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="natType" label="公网/内网" rules={[{ required: true, message: '请选择公网/内网' }]} >
                <Radio.Group >
                  <Radio value="public">公网</Radio>
                  <Radio value="inner">内网</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="machineRoomType" label="机房类型"   rules={[{ required: true, message: '请选择机房类型' }]} >
                <Radio.Group >
                  {roomPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="hasUPS"
                label={<span>UPS <Tooltip title="设备不间断电源"><QuestionCircleOutlined /></Tooltip></span>}
                rules={[{ required: true, message: '请选择是否配置UPS' }]}
              >
                <Radio.Group >
                  {UPSPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="ipProtocol"
                rules={[{ required: true, message: '请选择IP协议栈' }]}
                label={<span>IP协议栈 <Tooltip title={IPTIP}><QuestionCircleOutlined /></Tooltip></span>}
              >
                <Radio.Group >
                  {IPPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Tip>提示：绑定设备前要确保设备联网</Tip>
              <Form.Item>
                <MyButton type="primary" htmlType="submit" block>
                  立即绑定
                </MyButton>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="交换机绑定" key="2">
            <Form 
              form={switchForm} 
              layout="horizontal" 
              onFinish={handleSwitchBind}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
            >
              <Form.Item
                name="sn"
                label='SN号'
                rules={[{ required: true, message: '请输入SN号' }]}
              >
                <Input placeholder="请输入SN号" addonAfter={<ScanOutlined style={{ fontSize: 22 }} onClick={() => handleScanClick('sn')} />} />
              </Form.Item>
              <Form.Item name="machineRoomType" label="机房类型"   rules={[{ required: true, message: '请输入SN号' }]} >
                <Radio.Group >
                  {roomPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="hasUPS"
                label={<span>UPS <Tooltip title="设备不间断电源"><QuestionCircleOutlined /></Tooltip></span>}
                rules={[{ required: true, message: '请选择是否配置UPS' }]}
              >
                <Radio.Group >
                  {UPSPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="ipProtocol"
                rules={[{ required: true, message: '请选择IP协议栈' }]}
                label={<span>IP协议栈 <Tooltip title={IPTIP}><QuestionCircleOutlined /></Tooltip></span>}
              >
                <Radio.Group >
                  {IPPickerRange.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item className={styles.submitBtn}>
                <MyButton type="primary" htmlType="submit" block>
                  立即绑定
                </MyButton>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </BindDeviceFormStyle>
    </BindDeviceStyle>
  );
}