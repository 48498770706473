// src/utils/websocket.ts
export interface WSMessage {
    type: string;
    data: any;
  }
  
  export class WebSocketService {
    private static instance: WebSocketService;
    private ws: WebSocket | null = null;
    private messageHandlers: Map<string, Set<(data: any) => void>> = new Map();
    private reconnectAttempts = 0;
    private maxReconnectAttempts = 5;
    private reconnectTimeout = 3000;
  
    private constructor() {
      this.connect();
    }
  
    static getInstance() {
      if (!WebSocketService.instance) {
        WebSocketService.instance = new WebSocketService();
      }
      return WebSocketService.instance;
    }

    private async connect() {
      const uid = localStorage.getItem('_uid');
      const token = localStorage.getItem('token');
      
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsHost = "pedge.network";
      const wsUrl = `${wsProtocol}//${wsHost}/api/v1/user/device/ws`;
      
      try {
        
        // 将请求头转换为协议字符串
        // const protocols = [`authorization.bearer.${token}`];
        
        this.ws = new WebSocket(`${wsUrl}?uid=${uid}`);
    
        this.ws.onopen = this.handleOpen.bind(this);
        this.ws.onmessage = this.handleMessage.bind(this);
        this.ws.onclose = this.handleClose.bind(this);
        this.ws.onerror = this.handleError.bind(this);
        
        // 添加重连逻辑
        // this.ws.addEventListener('close', () => {
        //   console.log('WebSocket连接关闭，5秒后尝试重连...');
        //   setTimeout(() => {
        //     this.connect();
        //   }, 5000);
        // });
      } catch (error) {
        console.error('WebSocket连接失败:', error);
        // 连接失败后也尝试重连
        setTimeout(() => {
          this.connect();
        }, 5000);
      }
    }
  
    private handleOpen() {
      console.log('WebSocket connected');
      this.reconnectAttempts = 0;
    }
  
    private handleMessage(event: MessageEvent) {
      try {
        const message: WSMessage = JSON.parse(event.data);
        console.log('message', message)
        const handlers = this.messageHandlers.get(message.type);
        if (handlers) {
          handlers.forEach(handler => handler(message.data));
        }
      } catch (error) {
        console.error('Failed to handle WebSocket message:', error);
      }
    }
  
    private handleClose() {
      console.log('WebSocket disconnected');
      // this.attemptReconnect();
    }
  
    private handleError(error: Event) {
      console.error('WebSocket error:', error);
    }
  
    // private attemptReconnect() {
    //   if (this.reconnectAttempts < this.maxReconnectAttempts) {
    //     this.reconnectAttempts++;
    //     console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
    //     setTimeout(() => this.connect(), this.reconnectTimeout);
    //   }
    // }
  
    subscribe(type: string, handler: (data: any) => void) {
      if (!this.messageHandlers.has(type)) {
        this.messageHandlers.set(type, new Set());
      }
      this.messageHandlers.get(type)!.add(handler);
    }
  
    unsubscribe(type: string, handler: (data: any) => void) {
      const handlers = this.messageHandlers.get(type);
      if (handlers) {
        handlers.delete(handler);
      }
    }
  
    send(type: string, data: any) {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify({ type, data }));
      } else {
        console.warn('WebSocket is not connected');
      }
    }
  }