import React from "react"
import { logoutPrint } from "@/api/user"
import { logout } from "@/redux/user/actions"
import { useAppDispatch } from '@/redux'
import styled from "styled-components"
import { LogoutOutlined, CopyOutlined } from "@ant-design/icons"
import { message } from 'antd'
const PopoverCard = styled.div`
  width: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const UserName = styled.div`
    margin-bottom: 10px;
`
const Logout = styled.a`
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    cursor: pointer;
`

const PopoverContent = ({ uid }: { uid: string }) => {
  const dispatch = useAppDispatch()
  const handleCopy = () => {
    navigator.clipboard.writeText(uid)
      .then(() => {
        message.success('复制成功')
      })
      .catch(err => {
        console.error('复制失败:', err)
      })
  }
  return <PopoverCard>
    <UserName>我的ID: {uid} 
      <CopyOutlined style={{ cursor: 'pointer', marginLeft: 4 }} onClick={handleCopy} />
    </UserName>
    <Logout
      href="/index"
      onClick={(e) => {
        e.preventDefault()
        logoutPrint().finally(() => {
          window.location.reload()
        })
        dispatch(logout())
      }}
    >
      <LogoutOutlined style={{ marginRight: 6 }} />退出登录
    </Logout>
  </PopoverCard>
}

export default PopoverContent