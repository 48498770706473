import { apiSlice } from '@/api/apiSlice'

  export interface DeviceType {
    country: string
    deviceId: string
    remark?: string
    upBandwidth: number
    lineNumber: number
    province: string
    isp: string
    dns?: string
    diallingType: string
    natType: string
    machineRoomType: string
    hasUPS: string
    ipProtocol: string
  }
export interface ExchangeDeviceType {
    sn: string
    machineRoomType: string
    ipProtocol: string
    hasUPS: string
}
export interface ScanDeviceType {
    deviceID?: string
    sn?: string
}
  
const bindDeviceSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    bindDevice: build.mutation<void, DeviceType>({
        query: (data) => {
          return {
            url: '/v1/device/register',
            data,
            method: 'POST',
          }
        },
      }),
    bindSwitch: build.mutation<void, ExchangeDeviceType>({
      query: (data) => {
        return {
          url: '/v1/device/register_switch',
          method: 'POST',
          data,
        }
      },
    }),
    scanDevice: build.mutation<void, ScanDeviceType>({
      query: (data) => {
        return {
          url: '/v1/user/device/ws_send',
          method: 'POST',
          data,
        }
      },
    }),
  }),
})
export const { 
  useBindDeviceMutation, 
  useBindSwitchMutation,
  useScanDeviceMutation
}  = bindDeviceSlice
