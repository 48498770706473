import styled from 'styled-components'
import { Button } from 'antd'
import { isMobile } from '@/utils/device'

export const BindDeviceStyle = styled.div`
  background-color: white;
  padding: 0 24px;
`
export const BindDeviceFormStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  min-height: 889px;
`
export const Tip = styled.div`
  margin: 10px 0;
  color: red;
`
export const MyButton = styled(Button)`
  margin-left: ${isMobile() ? '0' : '9%'};
`
